import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Button from '../../Components/Button'
import Card from '../../Components/Card'
import CategoryBtns from '../../Components/Categories'
import Container from '../../Components/Container'
import Section from '../../Components/Section'
import RedirectBtnCompoent from '../../Components/Section/RedirectBtnCompoent'
import MainTitle from '../../Components/Section/Title/Main'
import useNFT from '../../Contexts/NFT/useNFT'
import { getAllNFTLit, getExploreNFTLit } from '../../lib/NFT/index'
import { tr } from 'date-fns/locale'
import styles from './styles.module.scss'
import SponsorSpotLightSectionExplore from '../../Components/GEMSComponent/SponsorSpotLightSectionExplore'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import UserProfileLoader from '../../Components/Loader/userProfileLoader'
import UseAuth from "../../Contexts/Auth/useAuth";
import { getDeviceId } from '../../Hooks/getDeviceId'
import FilterCriteria from '../../Components/Categories/filterCriteria'
import MobileFIlterCriteria from '../../Components/Categories/MobileFIlterCriteria'
import SVG from "react-inlinesvg";
import {useMediaQuery} from "../../Hooks/useMediaQuery"
import Drawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';
import Model from "../../Components/Model";
import { browserName, isSafari } from 'react-device-detect'

const BreadCrumbs = dynamic(() => import('../../Components/Breadcrumbs'))
const Explore = ({ data, full_page, href, propsExploreData }, props) => {
  const mobileScreen = useMediaQuery("(max-width:767px)");

  const { nftState, viewMoreExplore, getExploreData } = useNFT()
  const [exploreData, setExploreData] = useState(propsExploreData ?? [])
  const [minHeightPage, setMinHeightPage] = useState()
  const [selectedSort, setSelectedSort] = useState('')
  const [isLoad, setIsLoad] = useState(true)
  const [showSpotLight, setShowSpotlight] = useState(true)
  const [loader, setLoader] = useState(false)
  const router = useRouter()
  const { enc_url } = router.query
  const { authState } = UseAuth();
  const [isOpenFilter, setIsOpenFilter] = useState(false)

  const [category, setCategory] = useState([])
  const [currency, setCurrency] = useState('ALL')
  const [sortBy, setSortBy] = useState()
  const [searchParam, setSearchParam] = useState('')

  let breadcrumbList = [
    { label: 'Explore', link: '/explore' },
    {
      label: `${enc_url}`,
      link: `/explore?enc_url=${enc_url}`,
    },
  ]

  const resetTimer = () => {
    setShowSpotlight(false)
    setTimeout(() => {
      setShowSpotlight(true)
    }, 100)
  }

  const viewmoreClick = async (name) => {
    setLoader(true)
    if (name === 'home') {
      if (
        nftState?.explorePagination?.count >
          nftState?.explorePagination?.currentCount ||
        (nftState?.explorePagination?.count === 0 &&
          nftState?.explorePagination?.currentCount === 0)
      ) {
        let params = {
          category: nftState.catagorySelected.toLowerCase(),
          page_no: nftState.explorePagination.page_no,
          page_size: 12,
          device_id: getDeviceId()
        }
        const response = await getAllNFTLit(params)
        if (response?.status === 200) {
          setLoader(false)
          viewMoreExplore(response.data)
        }
      }
      if (
        enc_url &&
        enc_url !== '' &&
        enc_url !== 'null' &&
        enc_url !== 'undefined'
      ) {
        let params = {
          page_no: nftState.explorePagination.page_no,
          page_size: 12,
          ipfs_value: enc_url,
          device_id: getDeviceId()
        }
        const response = await getAllNFTLit(params)
        if (response?.status === 200) {
          setLoader(false)
          viewMoreExplore(response.data)
        }
      }
    } else if (name === 'explore') {
      if (
        nftState?.explorePagination?.count >
          nftState?.explorePagination?.currentCount ||
        (nftState?.explorePagination?.count === 0 &&
          nftState?.explorePagination?.currentCount === 0)
      ) {
        let params = {
          category: JSON.parse(JSON.stringify(category.map((data) => {return data.value})))?.join(","),
          page_no: nftState.explorePagination.page_no,
          page_size: 12,
          sort : sortBy,
          device_id: getDeviceId(),
          currency: currency,
          search_text: searchParam
        }
        const response = await getExploreNFTLit(params)
        if (response?.status === 200) {
          setLoader(false)
          viewMoreExplore(response.data)
        }
      }
      if (
        enc_url &&
        enc_url !== '' &&
        enc_url !== 'null' &&
        enc_url !== 'undefined'
      ) {
        let params = {
          page_no: nftState.explorePagination.page_no,
          page_size: 12,
          ipfs_value: enc_url,
          device_id: getDeviceId()
        }
        const response = await getAllNFTLit(params)
        if (response?.status === 200) {
          setLoader(false)
          viewMoreExplore(response.data)
        }
      }
    }
  }

  const handleExploreApicall = async () => {
    setSelectedSort('')
    let ParamsExplore
    if (
      enc_url &&
      enc_url !== '' &&
      enc_url !== 'null' &&
      enc_url !== 'undefined'
    ) {
      ParamsExplore = { page_no: 0, page_size: 12, ipfs_value: enc_url, device_id: getDeviceId()}
    } else {
      ParamsExplore = { page_no: 0, page_size: 12, device_id: getDeviceId()}
    }
    
    let responseExplore = await getAllNFTLit(ParamsExplore)
    if (responseExplore?.status === 200) {
      getExploreData(responseExplore.data)
    }
  }
  useEffect(() => {
    if (full_page) {
      handleExploreApicall()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enc_url, nftState.updateNftData])
  useEffect(() => {
    if (exploreData !== data) {
      setExploreData(data)
    }
    setIsLoad(false)
    setTimeout(() => {
      setIsLoad(true)
    }, 0.1)
  }, [data, exploreData, nftState.updateNftData])
  const NFTData = (data, bit) => {
    return exploreData
      .filter((_, key) => {
        return (bit === 1 && isSafari) ? key <= 5 : bit === 1 ? key <= 3 : isSafari ? key >= 6 : key >= 4
      })
      .map((nft, index) => {
        let third = (index + 1) % 3
        if (third == 0) {
        }
        return (
          <Col
            key={index}
            className={`mb-5  ${styles.cardCol} ${
              third == 0 ? styles.setColPaddingThird : ''
            } ${styles.customDivWidth} `}
            // sm={12}
            // xs={12}
            // md={6}
            // lg={4}
            // xl={3}
            // xxl={3}
          >
            <Card
              className={`${styles.card__clr}  ${styles.exploreCard}`}
              videoWrapperProps={`${styles.videoplayerWrapper}`}
              {...nft}
            />
          </Col>
        )
      })
  }

  const onChangeFilter = async (value) => {
    setSelectedSort(value)
  }

  useEffect(() => {
      handleExploreApicall()
  }, [])
  return (
    <>
    <Section className={`${styles.explore_nfts}`}>
      <Container>
        <meta
          name="title"
          content="Explore - Largest Collection of NFTs & Crypto Collectables | NFTON"
        />
        <meta
          name="description"
          content="Explore the largest collection of rare NTFs and crypto-collectibles built on Ethereum. You can buy, sell your digital art easily and securely on NFTON."
        />
        <link rel="canonical" href="https://nfton.market/explore" />
        <div className={`${mobileScreen && styles.mobileView}`}>
        <MainTitle
          // className={`${full_page ? styles.title__clr : ""}`}
          title={'Explore NFTs'}
          renderComponent={
            full_page && !enc_url && !mobileScreen ? (
              <>
                <FilterCriteria 
                  className={`${full_page ? styles.fp__clr : ''}`} 
                  setCurrency = {setCurrency} 
                  currency={currency}
                  category={category} 
                  setCategory={setCategory}
                  sortBy={sortBy} 
                  setSortBy={setSortBy}
                  searchParam={searchParam}
                  setSearchParam={setSearchParam}
                  setLoader={setLoader}
                  // loade
                  />
              </>
            ) : (
              exploreData.length > 0 && !mobileScreen &&
              !enc_url && (
                <RedirectBtnCompoent title={`View More`} href={`/explore`} />
              )
            )
          }
          // viewAllRef={"/view-all"}
        />
        <div className={`${styles.mobileviewFilter}`} onClick={() => setIsOpenFilter(true)}>
        <SVG
                width="24px"
                height="24px"
                src="/assets/fi_filter.svg"
              />
        </div>
        </div>
        {enc_url && <BreadCrumbs crumbs={breadcrumbList} />}
        {full_page && !enc_url ? (
          <>
          </>
        ) : (
          ''
        )}
      </Container>
      <br />
      <Container>
        {exploreData.length > 0 ? (
          <div className={`${styles.listings} container p-md-0`}>
            {/* <Row className={`row__gridv w-100 m-md-0`}> */}
            <div className={isSafari ? `${styles.row_spacing_safari} w-100` : `${styles.row_spacing} w-100`}>
              {isLoad ? NFTData(exploreData, 1) : ''}
            </div>
            {/* </Row> */}
          </div>
        ) : (
          <div
            className={`${styles.listings} ${
              full_page ? styles.extraClassForFulPage : ''
            } container p-md-0 justify-content-center`}
          >
            <div className={`${styles.nodataavalabile}`}>
              <p>No items are available for this category.</p>
            </div>
          </div>
        )}
      </Container>
      {showSpotLight && !enc_url && full_page && (
        <SponsorSpotLightSectionExplore page_type={2} resetTimer={resetTimer} />
      )}
      <Container>
        {loader ? (
          <Row>
            {[1, 2, 3, 4, 5, 6, 7, 8].map((val, index) => {
              return (
                <Col
                  // key={index}
                  className={`mt-3 mb-3 ${styles.col_card__clr}`}
                  sm={12}
                  md={6}
                  lg={3}
                  xl={3}
                  xxl={3}
                >
                  <UserProfileLoader />
                </Col>
              )
            })}
          </Row>
        ) : exploreData.length > 0 ? (
          <div className={`${styles.listings} container p-md-0`}>
            {/* <Row className={`row__gridv w-100 m-md-0`}> */}
            <div className={isSafari ? `${styles.row_spacing_safari} w-100` : `${styles.row_spacing} w-100`}>
              {isLoad ? NFTData(exploreData, 2) : ''}
            </div>
            {/* </Row> */}
          </div>
        ) : (
          <div
            className={`${styles.listings} ${
              full_page ? styles.extraClassForFulPage : ''
            } container p-md-0 justify-content-center`}
          >
            <div className={`${styles.nodataavalabile}`}>
              <p>No items are available for this category.</p>
            </div>
          </div>
        )}
        <div className={styles.footer}>
          {full_page
            ? nftState?.explorePagination?.count >
                nftState?.explorePagination?.currentCount && (
                <Button
                  onClick={() => {
                    viewmoreClick("explore")
                  }}
                >
                  View More
                </Button>
              )
            : nftState?.explorePagination?.count >
                nftState?.explorePagination?.currentCount && (
                <Button
                  onClick={() => {
                    viewmoreClick("home")
                  }}
                  className={'mb-4'}
                >
                  View More
                </Button>
              )}
        </div>
      </Container>


    </Section>
      <Drawer
        width="90%"
        // handler={false}
        maskClosable="true"
        onClose={() => setIsOpenFilter(false)}
        open={isOpenFilter}
        onMaskClick={() => setIsOpenFilter(false)}
        className={styles.wrappwerCustom}
        level=".drawer1"
        placement="bottom"
        wrapperClassName={styles.wrappwerCustom}
        // levelMove={100}
      >
         <MobileFIlterCriteria 
                  className={`${full_page ? styles.fp__clr : ''}`} 
                  setCurrency = {setCurrency} 
                  currency={currency}
                  category={category} 
                  setCategory={setCategory}
                  sortBy={sortBy} 
                  setSortBy={setSortBy}
                  searchParam={searchParam}
                  setSearchParam={setSearchParam}
                  setLoader={setLoader}
                  setIsOpenFilter={setIsOpenFilter}
                  // loade
                  />
      </Drawer>
      </>
  )
}

export default Explore
