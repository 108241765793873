/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import useNFT from '../../Contexts/NFT/useNFT';
import { getExploreNFTLit, getFeatureNFTList } from '../../lib/NFT';
import styles from './styles.module.scss';
import UseAuth from '../../Contexts/Auth/useAuth';
import { getDeviceId } from '../../Hooks/getDeviceId';
import MobileSelectFilter from '../../Components/FormsElements/SelectFilter/MobileSelectFilter';
import Container from '../../Components/Container';
import SVG from 'react-inlinesvg';
import Button from '../../Components/Button';

import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

const categoryOptions = [
    { value: 'all', label: 'All NFTs' },
    { value: 'Art', label: 'Art' },
    { value: 'Artwork', label: 'AI ART' },
    { value: 'Music', label: 'Music' },
    { value: 'Video', label: 'Video' },
    { value: 'Photography', label: 'Photography' },
    { value: 'Gallery', label: 'Gallery' },
    { value: 'Giveaway', label: 'Giveaway' },
];

const sortByOptions = [
    {
        key: 'Price Low to High',
        value: 1,
    },
    {
        key: 'Price High to Low',
        value: 2,
    },
    {
        key: 'Recently Listed/Newest',
        value: 3,
    },
    {
        key: 'Oldest',
        value: 4,
    },
    {
        key: 'Recently Sold',
        value: 5,
    },
    {
        key: 'Highest Last Sale',
        value: 6,
    },
    {
        key: 'Most Favorited',
        value: 7,
    },
];
const FilterCriteria = ({
    className,
    setCurrency,
    currency,
    category,
    setCategory,
    sortBy,
    setSortBy,
    searchParam,
    setSearchParam,
    setLoader,
    setIsOpenFilter,
}) => {
    const { authState } = UseAuth();

    const cookies = new Cookies();
    const { updateExplore } = useNFT();

    const getNftData = async (category, sortBy, currency, searchValue) => {
        // setLoader(true)
        let params = {};
        params = {
            category: JSON.parse(
                JSON.stringify(
                    category?.map((data) => {
                        return data.value;
                    })
                )
            )?.join(','),
            page_no: 0,
            page_size: 12,
            sort: sortBy,
            device_id: getDeviceId(),
            currency: currency,
            search_text: searchValue,
        };
        const res = await getExploreNFTLit(params);
        if (res?.status === 200) {
            updateExplore(res.data);
            setIsOpenFilter(false);
        }
    };

    const onSearchParam = (e) => {
        setSearchParam(e.target.value);
    };

    const onApplyFilter = () => {
        getNftData(category, sortBy, currency, searchParam);
    };

    const onChangeCategory = (value, event) => {
        if (event.action === 'select-option' && event.option.value === 'all') {
            setCategory(categoryOptions);
        } else if (
            event.action === 'deselect-option' &&
            event.option.value === 'all'
        ) {
            setCategory([]);
        } else if (event.action === 'deselect-option') {
            setCategory(value.filter((o) => o.value !== 'all'));
        } else if (value.length === categoryOptions.length - 1) {
            setCategory(categoryOptions);
        } else {
            setCategory(value);
        }
    };

    return (
        <header className={`${styles.header}`} id="mainHeader">
            <Container>
                <div className={`${styles.title_res} mt-3`}>
                    <h3 className={`${styles.title_res1}`}>Filters</h3>
                    <div>
                        <span className="mt-2 p-3">
                            <a
                                className={`${styles.removeFilter}`}
                                onClick={() => {
                                    setCategory([]);
                                    setCurrency('ALL');
                                    setSortBy('');
                                    setSearchParam('');
                                    // getNftData([], "", "ALL", "");
                                }}
                            >
                                Clear All
                            </a>
                        </span>
                        <span>
                            <button onClick={() => setIsOpenFilter(false)}>
                                <SVG
                                    src="/assets/ResponsiveImages/fi_Close.svg"
                                    width="20px"
                                    height="20px"
                                />
                            </button>
                        </span>
                    </div>
                </div>
                <hr />

                <div>
                    <form
                        action="#"
                        className={`${styles.searchFilter} ${styles.input_res}`}
                    >
                        <input
                            type="text"
                            placeholder="Search by name"
                            value={searchParam}
                            onChange={(e) => onSearchParam(e)}
                            className={`${styles.input_res}`}
                            maxLength={10}
                        />
                        <button
                            className={`${styles.search_btn}`}
                            type="button"
                            aria-label={'Search Button'}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                            >
                                <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
                            </svg>
                        </button>
                    </form>
                </div>

                <div className={` ${styles.filter_res} mt-3 ml-1`}>
                    <div className="Categories">
                        <div className={`${styles.clearAll_res}`}>
                            <ReactMultiSelectCheckboxes
                                options={categoryOptions}
                                placeholderButtonLabel="Categories"
                                onChange={onChangeCategory}
                                className="multibox"
                                value={category}
                            />
                        </div>
                    </div>
                    <div className="mt-2">
                        <MobileSelectFilter
                            options={sortByOptions}
                            label={`Sort By Filter`}
                            placeHolder={'Sort By Filter'}
                            id="filter"
                            onChangeHandler={(value) => {
                                setSortBy(value);
                            }}
                            defaultOption="Sort By"
                            currentValue={sortBy}
                            category={category?.map((data) => {
                                return data.value;
                            })}
                            bit="1"
                            noBorder={true}
                            // disabled={category?.length === 1 && category.includes('Giveaway')}
                        />
                    </div>
                    <div className="subFilter m-2 mt-3">
                        <span className={`${styles.currency_res}`}>
                            Select Currency
                        </span>
                        <div className={`${styles.collection_tabs} mt-3`}>
                            <input
                                onChange={(e) => {
                                    setCurrency('ALL');
                                }}
                                type="radio"
                                className={`${styles.tabs_radio}`}
                                name="tabs-example"
                                id="tab1"
                                checked={currency === 'ALL' ? 'checked' : ''}
                            />
                            <label
                                htmlFor="tab1"
                                className={`${styles.tabs_label}`}
                            >
                                All Currency
                            </label>
                            <input
                                onChange={(e) => {
                                    setCurrency('ETH');
                                }}
                                type="radio"
                                className={`${styles.tabs_radio}`}
                                name="tabs-example"
                                id="tab2"
                                checked={currency === 'ETH' ? 'checked' : ''}
                            />
                            <label
                                htmlFor="tab2"
                                className={`${styles.tabs_label}`}
                            >
                                ETH
                            </label>
                            <input
                                onChange={(e) => {
                                    setCurrency('MATIC');
                                }}
                                type="radio"
                                className={`${styles.tabs_radio}`}
                                name="tabs-example"
                                id="tab3"
                                checked={currency === 'MATIC' ? 'checked' : ''}
                            />
                            <label
                                htmlFor="tab3"
                                className={`${styles.tabs_label}`}
                            >
                                Polygon
                            </label>
                        </div>
                        <Button
                            onClick={() => onApplyFilter()}
                            className={`${styles.btns}`}
                        >
                            Apply
                        </Button>
                    </div>
                </div>
            </Container>
        </header>
    );
};

export default FilterCriteria;
