import React from "react";
import Link from "next/link";

import styles from "./styles.module.scss";

const RedirectBtnCompoent = ({ classname, title, icon, href }, props) => {
  return (
    <div className={`${styles.redirectbtn} ${classname} `}>
      <Link href={`${href}`}>
        <a>
          {title}
          <span className={styles.icon}>
            {icon ? (
              { icon }
            ) : (
              <svg
                width="8"
                height="14"
                viewBox="0 0 8 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 13L7 7L1 1"
                  stroke="#666666"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </span>
        </a>
      </Link>
    </div>
  );
};

export default RedirectBtnCompoent;
