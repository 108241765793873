/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import useNFT from "../../Contexts/NFT/useNFT";
import { getExploreNFTLit, getFeatureNFTList } from "../../lib/NFT";
import styles from "./styles.module.scss";
import UseAuth from "../../Contexts/Auth/useAuth";
import { getDeviceId } from "../../Hooks/getDeviceId";
import SelectFilter from "../../Components/FormsElements/SelectFilter";

import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

const categoryOptions = [
  { value: "all", label: "All NFTs" },
  { value: "Art", label: "Art" },
  { value: "Artwork", label: "AI ART" },
  { value: "Music", label: "Music" },
  { value: "Video", label: "Video" },
  { value: "Photography", label: "Photography" },
  { value: "Gallery", label: "Gallery" },
  { value: "Giveaway", label: "Giveaway" },
];

const sortByOptions = [
  {
    key: "Price Low to High",
    value: 1,
  },
  {
    key: "Price High to Low",
    value: 2,
  },
  {
    key: "Recently Listed/Newest",
    value: 3,
  },
  {
    key: "Oldest",
    value: 4,
  },
  {
    key: "Recently Sold",
    value: 5,
  },
  {
    key: "Highest Last Sale",
    value: 6,
  },
  {
    key: "Most Favorited",
    value: 7,
  },
];
const FilterCriteria = ({
  className,
  setCurrency,
  currency,
  category,
  setCategory,
  sortBy,
  setSortBy,
  searchParam,
  setSearchParam,
  setLoader
}) => {
  const { authState } = UseAuth();

  const cookies = new Cookies();
  const { updateExplore } = useNFT();

  const getNftData = async (category, sortBy, currency, searchValue) => {
    // setLoader(true)
    let params = {};
    params = {
      category: JSON.parse(
        JSON.stringify(
          category?.map((data) => {
            return data.value;
          })
        )
      )?.join(","),
      page_no: 0,
      page_size: 12,
      sort: sortBy,
      device_id: getDeviceId(),
      currency: currency,
      search_text: searchValue
    };
    const res = await getExploreNFTLit(params);
    if (res?.status === 200) {
      updateExplore(res.data);
    }
  };

  const onSearchParam = (e) => {
    setSearchParam(e.target.value);
      getNftData(category, sortBy,currency, e.target.value);
  }

  const onChangeCategory = (value, event) => {
    if (event.action === "select-option" && event.option.value === "all") {
      setCategory(categoryOptions);
      getNftData(categoryOptions, sortBy, currency, searchParam);
    } else if (
      event.action === "deselect-option" &&
      event.option.value === "all"
    ) {
      setCategory([]);
      getNftData([], sortBy, currency, searchParam);
    } else if (event.action === "deselect-option") {
      setCategory(value.filter((o) => o.value !== "all"));
      getNftData(
        value.filter((o) => o.value !== "all"),
        sortBy,
        currency,
        searchParam
      );
    } else if (value.length === categoryOptions.length - 1) {
      setCategory(categoryOptions);
      getNftData(categoryOptions, sortBy, currency, searchParam);
    } else {
      setCategory(value);
      getNftData(value, sortBy, currency, searchParam);
    }
  };

  return (
    <div className={` ${styles.filter}`}>
      <div>
        <div className="Categories">
      <div className={`${styles.clearAll}`}>
        <a
          className={`${styles.removeFilter}`}
          onClick={() => {
            setCategory([]);
            setCurrency("ALL");
            setSortBy("");
            setSearchParam('')
            getNftData([], "", "ALL", '');
          }}
        >
          Clear All
        </a>
          <ReactMultiSelectCheckboxes
            options={categoryOptions}
            placeholderButtonLabel="Categories"
            onChange={onChangeCategory}
            className="multibox"
            value={category}
            />
            </div>
        </div>
      </div>
      <div>
        <SelectFilter
          options={sortByOptions}
          label={`Sort By Filter`}
          placeHolder={"Sort By Filter"}
          id="filter"
          onChangeHandler={(value) => {
            setSortBy(value);
            getNftData(category, value, currency, searchParam);
          }}
          defaultOption="Sort By"
          currentValue={sortBy}
          category= {category?.map((data) => {
            return data.value;
          })}
          bit="1"
          // disabled={category?.length === 1 && category.includes('Giveaway')}
        />
      </div>
      <div className="subFilter">
        <div className={`${styles.collection_tabs}`}>
          <input
            onChange={(e) => {
              setCurrency("ALL");
              getNftData(category, sortBy, "ALL", searchParam);
            }}
            type="radio"
            className={`${styles.tabs_radio}`}
            name="tabs-example"
            id="tab1"
            checked={currency === "ALL" ? "checked" : ""}
          />
          <label htmlFor="tab1" className={`${styles.tabs_label}`}>
            All Currency
          </label>
          <input
            onChange={(e) => {
              setCurrency("ETH");
              getNftData(category, sortBy, "ETH", searchParam);
            }}
            type="radio"
            className={`${styles.tabs_radio}`}
            name="tabs-example"
            id="tab2"
            checked={currency === "ETH" ? "checked" : ""}
          />
          <label htmlFor="tab2" className={`${styles.tabs_label}`}>
            ETH
          </label>
          <input
            onChange={(e) => {
              setCurrency("MATIC");
              getNftData(category, sortBy, "MATIC", searchParam);
            }}
            type="radio"
            className={`${styles.tabs_radio}`}
            name="tabs-example"
            id="tab3"
            checked={currency === "MATIC" ? "checked" : ""}
          />
          <label htmlFor="tab3" className={`${styles.tabs_label}`}>
            Polygon
          </label>
        </div>
      </div>

      <div>
        <form action="#" className={`${styles.searchFilter}`}>
          <input
            type="text"
            placeholder="Search by name"
            value={searchParam}
            onChange={(e) => onSearchParam(e)}
            maxLength={10}
          />
          <button
            className={`${styles.search_btn}`}
            type="button"
            aria-label={"Search Button"}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" />
            </svg>
          </button>
          {/* <button
            aria-label={"Close"}
            type="button"
            // onClick={() => handleSearchBarState()}
            className={`${styles.close}`}
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z" />
            </svg>
          </button> */}
        </form>
      </div>
    </div>
  );
};

export default FilterCriteria;
