// import { useWindowResize } from "hooks/useWindowResize";
import React from 'react';
import { Form } from 'react-bootstrap';
import styles from './styles.module.scss';

const SelectFilter = ({
    defaultSelectValue,
    currentValue,
    onChangeHandler,
    label,
    options,
    size,
    value,
    disabled,
    category,
    bit,
    noBorder,
    ...props
}) => {
    const renderOptions = (options) => {
        return options.map((item, index) => (
            <option
                key={index}
                label={item.key}
                value={item.value}
                defaultValue={item === value}
                disabled={
                    bit === '1' &&
                    category?.length === 1 &&
                    category?.includes('Giveaway') &&
                    (item.value === 5 || item.value === 6)
                }
            >
                {item.key}
            </option>
        ));
    };

    return (
        <div className={`${styles.rst__clr} selectInput position-relative`}>
            <Form.Control
                value={currentValue}
                onChange={(e) => onChangeHandler(e.target.value)}
                className={`Inputstyle customStyle ${styles.customStyle_res} ${props.className}`}
                label={`--- Select ---`}
                as="select"
                size={size ?? 'lg'}
                // custom
            >
                <option value="" key={-1}>
                    {props?.defaultOption
                        ? props.defaultOption
                        : '--- Select ---'}
                </option>
                {renderOptions(options, value)}
            </Form.Control>
        </div>
    );
};
export default SelectFilter;
